<template>
  <header class="header" role="banner">
    <div class="header__container" style="display: flex;align-items: center;position: relative">
      <a class="header__logo logo" href="/" rel="home" title="Pactera" target="_self">
        <img  :src="corporation.cLogo" style="height: 72px; width: 140px;">

      </a>
<!--      <button style="cursor: pointer;font-size: 14px;padding: 10px 4px;border-radius: 8px;background: #1c7bce;color: #FFFFFF;border: none">联系我们</button>-->
      <nav class="header__main-nav" style="width: 80%;position: absolute;bottom:0;right: 0">
        <div class="menu-header-menu-container">
          <ul id="header-menu" class="menu">
            <li class="menu-item-has-children " @mouseover="mouseOverFun(index)" @mouseleave="mouseLeaveFun(index)" :class="navActiveValue == index ? 'active' : ''" v-for="(item,index) in headerHomeList" :key="index">
              <a :class="headerActiveHome == index ? 'navActiveClass': ''">{{item.tName}}</a>
              <div class="menu__submenu">
                <span class="menu__submenu-title">{{item.title}}</span>
                <ul class="menu__submenu-list"  >
                  <li class="menu__submenu-list__item " v-for="(itemSon,index) in item.secondTypes" :key="index">
                    <a @click.stop="navFun(itemSon)">
                      {{itemSon.tName}}
                    </a>
                  </li>
                </ul>

                <img :src="item.tImage" width="320" height="195">
              </div>
            </li>
          </ul>

        </div>

      </nav>

      <div id="navToggler" class="mobile-nav-toggler" @click.stop="openMenuFun()">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <!--    手机端菜单弹出层-->
    <el-drawer
        class="homeMenuOpenClass"
        :visible.sync="direction"
        :with-header="false"

    >
      <el-menu
          router="true"
          class="el-menu-vertical-demo"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
      >
        <el-submenu :index="index" v-for="(item,index) in headerHomeList" :key="index">
          <template slot="title">
            <span>{{item.tName}}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item :route="{ path: '/navDetail', query: { itemValue: JSON.stringify(itemSon) } }"   :index="`${index}-${indexSon}`" v-for="(itemSon,indexSon) in item.secondTypes" :key="indexSon">{{itemSon.tName}}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </el-drawer>
  </header>
</template>

<script>
import {home} from '@/api/home'
import http from  '@/configUrl'
export default {
  name: "header",
  data(){
    return{
      headerActiveHome:999,
      direction:false, //控制抽屉是否显示
      navActiveValue:999,
      headerHomeList:[],
      corporation:{},// 公司部分信息
    }
  },
  created() {
    this.headerActiveHome = this.$route.query.fatherValue
  },
  watch: {
    $route: {
      handler(val, oldval) {
        console.log(val);//新路由信息
        console.log(oldval);//老路由信息

      },
      // 深度观察监听
      deep: true
    }
  },
  mounted() {
    this.getHomeInfoFun()
  },
  methods:{
    //获取导航列表
    getHomeInfoFun() {
      home().then(response=>{
       let arr   = response.types
        arr.forEach(el=>{
          if(!this.isRelativePath(el.tImage)){
            el.tImage = http + el.tImage
          }
          this.headerHomeList.push(el)
        })
        this.corporation =response.corporation
        if(!this.isRelativePath(this.corporation.cLogo)){
          this.corporation.cLogo = http + this.corporation.cLogo
        }
      })
    },
    mouseOverFun(e){
      // console.log(e)
      this.navActiveValue = e
    },
    mouseLeaveFun(){
      this.navActiveValue = 999
    },
  //  移动端  点击展开菜单
    openMenuFun(){
      this.direction= true
    },
  //  切换导航栏
    navFun(item){
      console.log(123456)
      // this.headerActiveHome = item.value
      let navValue = JSON.stringify(item)
      this.$router.replace({path:'/navDetail',query:{itemValue:navValue}})
    },
    // 判断图片是相对路径爱是绝对路径
    isRelativePath(path) {
      // 匹配相对路径的正则表达式
      var regex = /^http?/ig;
      return regex.test(path);
    },
    handleMenuSelect(){
      console.log(132465)
    }
  }
}
</script>

<style scoped lang="scss">
@import url(../assets/css/marketing.css);
@import url(../assets/css/marketing.2css.css);
.clearOpacity{
  opacity: 1;
}
li{
  list-style: none;
  ul{
    padding-left: 0;
  }
}
.huActive{
  //opacity: 0;
}
 a{
  cursor: pointer;
}
.header__main-nav .menu{
  border: none;
}
::v-deep .homeMenuOpenClass .el-drawer__body{
  padding: 0;
}
.navActiveClass{
  color: #ce1b2e !important;
}
::v-deep .el-drawer{
  width: 50% !important;

  overflow-y: hidden;
}
::v-deep  .el-menu-item-group{
  overflow-y: hidden;
}
</style>
