<template>
<!--  联系我们-->
  <div class="us-content">
    <div class="us-content-box">

      <div class="us-content-form">
        <h2>感谢联系丰之地</h2>
        <el-form  label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="姓名" prop="name">
            <el-input size="mini" v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="phone">
            <el-input size="mini" v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="公司名称" prop="companyName">
            <el-input size="mini" v-model="ruleForm.companyName"></el-input>
          </el-form-item>
          <el-form-item label="职务" prop="duties">
            <el-input size="mini" v-model="ruleForm.duties"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="mailbox">
            <el-input size="mini" v-model="ruleForm.mailbox"></el-input>
          </el-form-item>
          <el-form-item label="需求" prop="demand">
            <el-input type="textarea" v-model="ruleForm.demand"></el-input>
          </el-form-item>
<!--          <el-form-item >-->
<!--            <div style="display: inline-block;width: 40%;">1234</div>-->
<!--            <el-input style="display: inline-block;width: 40%;" placeholder="请输入验证码" v-model="ruleForm.code"></el-input>-->
<!--          </el-form-item>-->
          <el-form-item >
            <div class="substy" @click.stop="subMitFun()">立即提交</div>
          </el-form-item>
        </el-form>
      </div>
      <div class="us-content-info">
        <h3>数字时代，企业如何事件对跨越式发展</h3>
        <div class="us-content-info-one">154家财富500强企业的共同选择</div>
        <div class="us-content-info-one">我们的愿景：成就更美好的数字化新世界</div>
        <div class="contactUsBottom">
          <div>现在联系丰之地</div>
          <div>立刻定制您的专属解决方案</div>
          <div>400-822-8372</div>
          <div>销售邮箱：sales@pactera.com</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {contactUsApi} from  '@/api/home'
export default {
  name: "contactUs",
  data(){
    return{
      ruleForm: {
        name: '',
        companyName:'',
        duties:'',
        mailbox:'',
        demand:'',
        code:'',
      },
      rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
        ],
        phone:[
          {required: true, message: '请输入您的联系方式', trigger: 'blur'}
        ],
        companyName:[
          {required: true, message: '请输入您的公司名称', trigger: 'blur'}
        ],
      }
    }
  },
  methods:{
    //联系方式提交
    subMitFun() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // alert('submit!');
          let data ={
            name:this.ruleForm.name,
            phone:this.ruleForm.phone,
            corporation:this.ruleForm.companyName,
            jober:this.ruleForm.duties,
            email:this.ruleForm.mailbox,
            needHelp:this.ruleForm.demand
          }
          contactUsApi(data).then((response)=>{
            // console.log(response)
            if(response.code == 200){
              this.$message({
                type:'success',
                message:'提交成功'
              })
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
@media screen and (min-width: 1024px){
  .us-content{
    width: 74%;
  }
  .us-content-box{
    display: flex;
    //align-items: center;
  }
  .us-content-form{
    width: 50%;
    background: #FFFFFF;
    box-sizing: border-box;
    padding: 10px;
    //height: 1024px;
  }
  .us-content-info{
    width: 50%;
    background: #003b81;
    position: relative;
  }
  .us-content-info{
    h3{
      color: #FFFFFF;
      font-weight: bold;
      font-size: 3rem;
      padding-left: 6rem;
      width: 60%;
    }
    .us-content-info-one{
      color: #FFFFFF;
      padding-left: 6rem;
      font-size: 1.4rem;
      line-height: 30px;
    }
    .contactUsBottom{
      position: absolute;
      left: 20px;
      bottom: 20px;
    }
  }
  .contactUsBottom div:nth-child(1), .contactUsBottom div:nth-child(2) ,.contactUsBottom div:nth-child(4){
    color: #FFFFFF;
    font-weight: bold;
    padding: 2px 0;
    font-size: 1.6rem;
  }
  .contactUsBottom div:nth-child(3){
    color: #FFC800;
    font-size: 3.6rem;
    padding: 20px 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .us-content{
    width: 80%;
  }
  .us-content-box{
    display: flex;
    //align-items: center;
  }
  .us-content-form{
    width: 50%;
    background: #FFFFFF;
    box-sizing: border-box;
    padding: 10px;
    //height: 1024px;
  }
  .us-content-info{
    width: 50%;
    background: #003b81;
    position: relative;
  }
  .us-content-info{
    h3{
      color: #FFFFFF;
      font-weight: bold;
      font-size: 3rem;
      padding-left: 6rem;
      width: 60%;
    }
    .us-content-info-one{
      color: #FFFFFF;
      padding-left: 6rem;
      font-size: 1.4rem;
      line-height: 30px;
    }
    .contactUsBottom{
      position: absolute;
      left: 20px;
      bottom: 20px;
    }
  }
  .contactUsBottom div:nth-child(1), .contactUsBottom div:nth-child(2) ,.contactUsBottom div:nth-child(4){
    color: #FFFFFF;
    font-weight: bold;
    padding: 2px 0;
    font-size: 1.6rem;
  }
  .contactUsBottom div:nth-child(3){
    color: #FFC800;
    font-size: 3.6rem;
    padding: 20px 0;
  }
}
@media screen and (max-width: 768px) {
  .us-content-info{
    text-align: center;
    padding: 10px 0;
    h3{
      color: #FFFFFF;
      font-weight: bold;
      font-size: 3rem;
      text-align: center;
    }
    .us-content-info-one{
      color: #FFFFFF;
      font-size: 1.4rem;
      line-height: 30px;
    }

  }
  .contactUsBottom div:nth-child(1), .contactUsBottom div:nth-child(2) ,.contactUsBottom div:nth-child(4){
    color: #FFFFFF;
    font-weight: bold;
    padding: 2px 0;
    font-size: 1.6rem;
  }
  .contactUsBottom div:nth-child(3){
    color: #FFC800;
    font-size: 3.6rem;
    padding: 20px 0;
  }
  .us-content{
    width: 90%;
  }
  .us-content-form{
    width: 100%;
    background: #FFFFFF;
    box-sizing: border-box;
    padding: 10px;
    //height: 1024px;
  }
  .us-content-info{
    width: 100%;
    background: #003b81;
    position: relative;
  }
}
.us-content{
  margin: 0 auto;
  background: #EcEcEc;
  padding: 20px 10px;
}


h2{
  font-size: 3rem;
  color: #01a2ff;

}
.substy{
  cursor: pointer;
  border-bottom: 8px solid #002550;
  background: #003b81;
  color: #FFFFFF;
  font-size: 1.4rem;
  width: 200px;
  text-align: center;
}
</style>
