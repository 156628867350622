<template>
  <div>
    <el-header style="padding: 0;height: auto">
      <headers/>
    </el-header>
    <el-main style="padding:0;overflow-y: hidden">
      <router-view :key="$route.fullPath"></router-view>
      <div class="backHomeSty" @click.stop="backHomeFun()" v-if="isHome">返回首页</div>
    </el-main>
  </div>
</template>

<script>
import headers from "@/components/header";
export default {
  name: "navMenu",
  components:{headers},
  data(){
    return{
      isHome:true
    }
  },
  watch:{
    $route(to){
      // console.log(to.path)
      if(to.path == '/home'){
        this.$nextTick(()=>{
          this.isHome = false
        })
      }else{
        this.$nextTick(()=>{
          this.isHome = true
        })
      }
    }
  },
  methods:{
    backHomeFun(){
      this.$router.replace('/home')
    }
  }
}
</script>

<style scoped>
@import url(../assets/css/marketing.css);
@import url(../assets/css/marketing.2css.css);
.backHomeSty{
  position: fixed;
  bottom: 20%;
  right: 4%;
  z-index: 9999;
  background: #1c7bce;
  color: #FFFFFF;
  padding: 20px 4px;
  border-radius: 4px;
  font-size: 1.4rem;
  cursor: pointer;
}
</style>
