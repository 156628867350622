<template>
  <div id="app">
    <router-view></router-view>

  </div>
</template>

<script>
//引入公共头部
// import headers  from "@/components/header";
//官网首页
// import home from '@/pages/home'
//  一级导航菜单
// import navDetail from "@/pages/navDetail";
//二级导航内容
// import navSonDetail from "@/pages/navSonDetail";
//联系我们
// import contactUs from "@/pages/contactUs";

//新闻页面
// import news from "@/pages/news";
export default {
  name: 'App',
  components: {
    // headers,
    // home
    // navDetail
    // navSonDetail
    // contactUs
    // news
  }
}
</script>

<style>

</style>
