<template>
  <div>
    <div class="banner form-group">
      <a href="/marketing-contact-us?comefrom=MarketingHome&amp;linkfrom=http%3A%2F%2Flocalhost%3A63342%2FziyingPc%2Fpages%2FHome.html%3F_ijt%3D5qd5le2q153nqm18kfa4dmq609" target="_blank" class="hidden-xs contactus"><img :src="topImg.bImage" class="img-responsive"></a>
      <a href="/marketing-contact-us?comefrom=MarketingHome&amp;linkfrom=http%3A%2F%2Flocalhost%3A63342%2FziyingPc%2Fpages%2FHome.html%3F_ijt%3D5qd5le2q153nqm18kfa4dmq609" target="_blank" class="visible-xs contactus"><img :src="topImg.bImage" class="img-responsive"></a>
    </div>
<!--    专业技术-->
    <div class="mod-Business">

      <div class="container">
        <div class="title titleDiy">
          <h2 style="text-align: center">
            {{newIntroTitle}}
          </h2>
        </div>
        <div class="row">
          <div class="col-md-3 col-sm-6 col-xs-6 form-group" v-for="(item,index) in newIntro" :key="index">
            <div class="mod mod-img">
              <a class="img-h contactus" target="_blank"><img :src="item.misImage"  class="img-responsive"></a>
              <h2>{{item.misTitle}}</h2>
              <p>{{item.misIntro}}</p>
              <div class="btnBox">
                <!--                        <a href="/marketing-contact-us?comefrom=MarketingHome" target="_blank" class="contactus">了解更多》</a>-->
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
<!--    方案视频-->
    <div class="section__wrap case-studies-wrap" >
      <section class="case-studies max-width">
        <div class="title">
          <h2 style="color: #FFFFFF">
            {{scenarioVideoTitle}}
          </h2>
        </div>
        <div class="case-study-video">

          <div class="left_2_pic">
            <div class="sub_thumb left_2_top">
              <div class="gradientCarouseBack" :style="`background:no-repeat url(${scenarioVideo[0].misImage});background-size: cover;background-position: center;`"></div>
              <div class="gradientCarouseMask" style=""></div>
              <div class="gradientCarouseContent">
                <img @click.stop="payVideoFun(scenarioVideo[0].misVideo)" id="playImg" class="videoPlay PopupVideoIcon" src="../assets/video.png"   trackid="">
                <div class="case-studies__item-content">
                  <h3>{{scenarioVideo[0].misTitle}}</h3>


                  <div class="case-studies__item-links">
<!--                    <a class="link-arrow" target="_blank" href="/solution/banking/bank-finance-data-solution">了解更多</a>-->
                  </div>
                </div>
              </div>
            </div>
            <div class="sub_thumb left_2_top">
              <div class="gradientCarouseBack" :style="`background:no-repeat url(${scenarioVideo[1].misImage});background-size: cover;background-position: center;`"></div>
              <div class="gradientCarouseMask" style=""></div>
              <div class="gradientCarouseContent">
                <img @click.stop="payVideoFun(scenarioVideo[1].misVideo)" id="playImg" class="videoPlay PopupVideoIcon" src="../assets/video.png"  videourl="/-/media/PacteraOW/PageImages/Video/1918.ashx" trackid="">
                <div class="case-studies__item-content">
                  <h3><p style="margin: 0px; padding: 0px; border: 0px;">{{scenarioVideo[1].misTitle}}</p></h3>


                  <div class="case-studies__item-links">

                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="right_2_pic">
            <div class="sub_thumb right_2_top">
              <div class="gradientCarouseBack" :style="`background:no-repeat url(${scenarioVideo[2].misImage});background-size: cover;background-position: center;`"></div>
              <div class="gradientCarouseMask" style=""></div>
              <div class="gradientCarouseContent">
                <img @click.stop="payVideoFun(scenarioVideo[2].misVideo)" id="playImg" class="videoPlay PopupVideoIcon" src="../assets/video.png" alt="中电文思海辉,软件开发外包公司" videourl="/-/media/PacteraOW/PageImages/Video/Enterprise-digital-operation-panorama-platform.ashx" trackid="">
                <div class="case-studies__item-content">
                  <h3>{{scenarioVideo[2].misTitle}}</h3>


                  <div class="case-studies__item-links">

                  </div>
                </div>
              </div>
            </div>
            <div class="sub_thumb right_2_top">
              <div class="gradientCarouseBack" :style="`background:no-repeat url(${scenarioVideo[3].misImage});background-size: cover;background-position: center;`"></div>
              <div class="gradientCarouseMask" style=""></div>
              <div class="gradientCarouseContent">
                <img @click.stop="payVideoFun(scenarioVideo[3].misVideo)" id="playImg" class="videoPlay PopupVideoIcon" src="../assets/video.png" alt="中电文思海辉,软件开发外包公司" videourl="/-/media/PacteraOW/PageImages/Video/20181102-xingzhengzhinao.ashx" trackid="">
                <div class="case-studies__item-content">
                  <h3>{{scenarioVideo[3].misTitle}}</h3>


                  <div class="case-studies__item-links">

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </section>
    </div>

    <div class="mod-Painspot">
      <div class="title">
        <h2>
          {{ifPerplexTitle}}
        </h2>
      </div>
      <div class="container">
        <div class="row PainspotCon">
          <div class="col-md-6 col-sm-6" v-for="(item,index) in ifPerplex" :key="index">
            <div class="media media-01" :style="`background:${item.misColor}`">
              <div class="media-body">
                <h3 class="media-heading">{{item.misTitle}}</h3>
                <div class="media-con">
                 <p>{{item.misTag}}</p>
                </div>

              </div>

            </div>
          </div>


        </div>
      </div>
    </div>

<!--    经典案例-->
    <div class="mod-case">

      <div class="title">
        <h2>
          {{classicCaseTitle}}
        </h2>
      </div>

      <div class="container">
        <div class="wrap" v-if="screenWidth >= 768">
          <div class="box1" v-for="(item,index) in classicCase" :key="index" :style="`background: no-repeat url(${item.misImage})`">
            <div class="box">
              <div class="box-title">{{item.misTitle}}</div>
              <div class="box-content">
                {{item.misIntro}}
              </div>
            </div>
            <div class="mask">
              <div class="box-title">{{item.misTitle}}</div>
              <div>{{item.misContext}}</div>
            </div>
          </div>
        </div>

        <!--    经典案例小尺寸-->
        <div class="jdal-small" v-else>
          <el-carousel indicator-position="outside">
            <el-carousel-item v-for="(item,index) in classicCase" :key="index">
              <div class="box1">
                <div class="box">
                  <div class="box-title">{{item.misTitle}}</div>
                  <div class="box-content">
                    {{item.misIntro}}
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>

      </div>
    </div>


<!--    近期新闻-->
    <div class="section__wrap related-wrap LatestNews" style="overflow-x:hidden ">
      <section class="related">

        <div class="title">
          <h2>
            {{recentNewTitle}}
          </h2>
        </div>
        <ul class="case-related">
          <li class="case-related__item" @click.stop="newsFun(item)" v-for="(item,index) in recentNew" :key="index">
            <a class="news__item"  target="_blank">
              <div class="news__item-img">
                <img :src="item.misImage"  width="350" height="260">
              </div>
              <div class="news__item-cont">

                <span>{{item.misDate}}</span>
                <h3>{{item.misTitle}}</h3>
                <div class="news__item-cont__richtext">{{item.misIntro}}</div>
              </div>
            </a>
          </li>

        </ul>
      </section>
    </div>

<!--    了解更多-->
    <div class="footer footer_diy" :style="`background: no-repeat url(${indexBottom.typeBottomValues[0].mtbImage})`" >
      <div class="footer-con" style="display: block" >
        <h2 style="display: block;width: 100%">{{indexBottom.mtbTitle}}</h2>
        <h3 style="display: block;width: 100%">{{indexBottom.mtbText}}</h3>
        <div class="icon-footer" style="display: block;width: 100%">
          <a @click.stop="goLiuYan()"  class="icon-msg contactus" target="_blank"><img style="width: 20px;" src="../assets/ly.png" /><span>给我留言</span></a>
          <a class="icon-400"><img src="../assets/dh.png" style="width: 20px;"  /><span>{{indexBottom.typeBottomValues[0].mtbOther}}</span></a>
        </div>
      </div>
    </div>

    <div class="copyright">
      <p>{{corporation.cGongAn}}</p>
      <p @click.stop="openOtherUrlFun()" style="cursor: pointer">{{corporation.cGongXin}}</p>
<!--      <a href="https://beian.miit.gov.cn/">{{corporation.cGongXin}}</a>-->
    </div>

<!--    视频组件-->
    <div style="position: fixed;top: 50%;left: 50%;transform: translate(-50%,-50%);z-index: 999" class="videoBox" v-if="videoIsShow">
      <video class="videoSize" controls >
        <source :src="videoUrl" type="video/mp4">
      </video>
      <img @click.stop="videoIsShow=false" src="../assets/closeAnNiu.png" style="position: absolute;right: 30px;width: 30px;top: 30px">
    </div>
  </div>
</template>

<script>
import {home} from '@/api/home'
import http from '@/configUrl'
export default {
  name: "home",
  data(){
    return{
      corporation:{},// 公司部分信息
      videoIsShow:false,
      videoUrl:'',
      direction:false, //抽屉
      screenWidth: null, // 屏幕尺寸,
      topImg:[],  //顶部图片
      scenarioVideo:[],  //方案视频
      newIntro:{},  // 专业技术
      ifPerplex:[], // 在项目执行中遇到的问题
      classicCase:[], //经典案例
      recentNew:[], //近期新闻
      indexBottom:{},   //给我留言
      newIntroTitle:'',  //标题1
      scenarioVideoTitle:'',  //标题2，
      ifPerplexTitle:'' , //标题3，
      classicCaseTitle:'',  //标题4
      recentNewTitle:'' ,  // 标题5


    }
  },
  created() {
    this.screenWidth = document.body.clientWidth
    window.onresize = ()=>{
      return(()=>{
        this.screenWidth = document.body.clientWidth
      })()
    }
  },
  mounted() {
  //  获取首页列表数据
    this.getHomeInfoFun()
  },
  watch:{
    screenWidth: {
      handler: function (val,) {
        this.screenWidth=val
      },

      immediate: true
    },

  },
  methods:{
    //
    openOtherUrlFun(){
      window.open('https://beian.miit.gov.cn/')
    },
    //去留言
    goLiuYan(){
      this.$router.push({path:'/contactUs'})
    },
    // 判断图片是相对路径爱是绝对路径
    isRelativePath(path) {
      // 匹配相对路径的正则表达式
      var regex = /^http?/ig;
      return regex.test(path);
    },
    getHomeInfoFun(){
      home().then(res=>{
        this.topImg = res.topBanner[0]
        if(!this.isRelativePath(this.topImg.bImage)){
          this.topImg.bImage = http + this.topImg.bImage
        }
      //  专业技术
        this.newIntro=[]
        let newIntroObj = res.newIntro
        newIntroObj.indexStore.forEach(el=>{
          if(!this.isRelativePath(el.misImage)){
            el.misImage = http + el.misImage
          }
          this.newIntro.push(el)
        })
      //  方案视频
        let faVideo = res.solutionVideo.indexStore
        faVideo.forEach(el=>{
          if(!this.isRelativePath(el.misVideo)){
            el.misVideo = http + el.misVideo
          }
          if(!this.isRelativePath(el.misImage)){
            el.misImage = http + el.misImage
          }
          this.scenarioVideo.push(el)
        })

      //  在项目执行中遇到的问题
        this.ifPerplex = res.ifPerplex.indexStore

      //  经典案例
        let classicCaseArr = res.classicCase.indexStore
        classicCaseArr.forEach(el=>{
          if(!this.isRelativePath(el.misImage)){
            el.misImage = http +el.misImage
          }
          this.classicCase.push(el)
        })

      //  近期新闻
        let recentNewArr = res.recentNew.indexStore
        recentNewArr.forEach(el=>{
          if(!this.isRelativePath(el.misImage)){
            el.misImage = http + el.misImage
          }
          this.recentNew.push(el)
        })

      //  给我留言
        this.indexBottom = res.indexBottom
        if(!this.isRelativePath(this.indexBottom.typeBottomValues[0].mtbImage)){
          this.indexBottom.typeBottomValues[0].mtbImage = http + this.indexBottom.typeBottomValues[0].mtbImage
        }
      //  公司信息
        this.corporation =res.corporation

        this.newIntroTitle=res.newIntro.mitTitle  //标题1
           this.scenarioVideoTitle=res.solutionVideo.mitTitle  //标题2，
            this.ifPerplexTitle=res.ifPerplex.mitTitle  //标题3，
            this.classicCaseTitle=res.classicCase.mitTitle  //标题4
        this.recentNewTitle=res.recentNew.mitTitle  // 标题5
      })
    },
    newsFun(item){
      let value = JSON.stringify(item)
      this.$router.push({path:'/news',query:{value:value}})
    },
    //播放视频
    payVideoFun(value){
      this.videoUrl = value
      this.videoIsShow = true
    }
  }

}
</script>

<style scoped lang="scss">
//@import url(../assets/css/marketing.css);
//@import url(../assets/css/marketing.2css.css);
.swiper-slide{
  width: 300px !important;
}

@media screen and (min-width: 768px) { /*当屏幕尺寸大于768px时，应用下面的CSS样式*/
  .videoSize{
    width: 800px;
    height: 700px;

  }
  .wrap {
    width: 100%;
    max-width: 1380px;
    //height: 300px;
    /* background-color: sandybrown; */
    position: relative;
    margin: 0 auto;

    .box1 {
      text-align: center;
      display: inline-block;
      width: 20%;
      height: 400px;
      margin: 0 2%;
      /* background-color: salmon; */
    }

    .box1:nth-child(1) .box{
      //background-image: url('../assets/pic_05.jpg');
    }

    .box1:nth-child(2) .box{
      //background-image: url('../assets/pic_06.jpg');
    }

    .box1:nth-child(3) .box{
      //background-image: url('../assets/pic_07.jpg');
    }

    .box1:nth-child(4) .box{
      //background-image: url('../assets/pic_08.jpg');
    }
    .box{
      width: 23%;
      height: 100%;
      box-sizing: border-box;
      font-size: 4rem;
      color: #fff;
      //padding: 38px 0 0 38px;
      position: absolute;
      perspective: 1000px;
      transition: 0.5s ease-in-out;
      .box-title{
        font-size: 2rem;
        padding: 4rem 0;
      }
      .box-content{
        font-size: 1.6rem;
        text-align: center;
        padding: 6rem 3rem;
      }

    }
    .mask{
      width: 23%;
      height: 100%;
      background-color: #01a2ff;
      color: #fff;
      //font-size: 30px;
      text-align: center;
      //line-height: 300px;
      font-family: '微软雅黑';
      font-weight: bold;
      position: absolute;
      backface-visibility:hidden;
      perspective: 1000px;
      transform: rotateY(-180deg);
      transition: 0.5s ease-in-out;
      .box-title{
        font-size: 1.6rem;
        padding: 6rem 4rem ;
      }
    }
    .mask div:nth-child(2){
      font-size: 2rem;
    }
    .box1:hover .box{
      transform: rotateY(-180deg);
    }
    .box1:hover .mask{
      transform: rotateY(-360deg);
    }
  }

}


@media screen and (max-width:768px){
  .videoSize{
    width: 300px;
    height: 300px;

  }
    .box1 {
      text-align: center;
      display: inline-block;
      width: 100%;
      height: 400px;
      margin: 0 2%;
      /* background-color: salmon; */
    }

    .box1:nth-child(1) .box{
      //background-image: url('../assets/pic_05.jpg');
    }

    .box1:nth-child(2) .box{
      //background-image: url('../assets/pic_06.jpg');
    }

    .box1:nth-child(3) .box{
      //background-image: url('../assets/pic_07.jpg');
    }

    .box1:nth-child(4) .box{
      //background-image: url('../assets/pic_08.jpg');
    }
    .box{
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      font-size: 4rem;
      color: #fff;
      //padding: 38px 0 0 38px;
      position: absolute;
      perspective: 1000px;
      transition: 0.5s ease-in-out;
      .box-title{
        font-size: 2rem;
        padding: 4rem 0;
      }
      .box-content{
        font-size: 1.6rem;
        text-align: center;
        padding: 6rem 3rem;
      }

    }
    .mask{
      width: 100%;
      height: 100%;
      background-color: #01a2ff;
      color: #fff;
      //font-size: 30px;
      text-align: center;
      //line-height: 300px;
      font-family: '微软雅黑';
      font-weight: bold;
      position: absolute;
      backface-visibility:hidden;
      perspective: 1000px;
      transform: rotateY(-180deg);
      transition: 0.5s ease-in-out;
      .box-title{
        font-size: 1.6rem;
        padding: 6rem 4rem ;
      }
    }
    .mask div:nth-child(2){
      font-size: 2rem;
    }
    //.box1:hover .box{
    //  transform: rotateY(-180deg);
    //}
    //.box1:hover .mask{
    //  transform: rotateY(-360deg);
    //}
  }
.case-related__item a{
  cursor: pointer;
}

.videoBox{
  background: rgba(0,0,0,0.7);
  position: relative;
}
</style>
