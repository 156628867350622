<template>
<!--  友情连接-->
  <div class="friendly-links">
    <div class="friendly-links-top">
      <img :src="topImg">
      <div class="friendly-links-top-text">
        {{linkValue.name}}
      </div>
    </div>
<!--    内容-->
    <div class="link-content">
      <div v-html="linkValue.valueText"></div>
    </div>
<!--    底部-->
    <footerContent></footerContent>
  </div>
</template>

<script>
import http from '@/configUrl'
import footerContent from "@/components/footerContent";
import {getThreeApi} from '@/api/home'
export default {
  name: "friendlyLinks",
  components:{footerContent},
  data(){
    return{
      linkValue: {},
      topImg:'',
    }
  },
  created() {
    this.linkValue =JSON.parse(this.$route.query.linkValue)
  },
  mounted() {

    this.getTopImg()
  },
  methods:{
    getTopImg(){
      getThreeApi({Logo:this.linkValue.logo}).then(res=>{
        if(res.code == 200){
          this.topImg = http + res.banner[0].bImage
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .friendly-links-top{
    position: relative;
    img{
      width: 100%;
    }
    .friendly-links-top-text{
      position: absolute;
      left: 20%;
      top: 50%;
      transform: translate(-50%,-50%);
      color: #FFFFFF;
      font-size: 3.4rem;
      font-weight: 600;
    }
  }
  .link-content{
    padding: 40px 0;
    width: 80%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 800px) and (max-width: 1200px) {
  .friendly-links-top{
    position: relative;
    img{
      width: 100%;
    }
    .friendly-links-top-text{
      position: absolute;
      left: 20%;
      top: 50%;
      transform: translate(-50%,-50%);
      color: #FFFFFF;
      font-size: 3.4rem;
      font-weight: 600;
    }
  }
  .link-content{
    padding: 40px 0;
    width: 90%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 800px) {
  .friendly-links-top{
    position: relative;
    img{
      width: 100%;
    }
    .friendly-links-top-text{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      color: #FFFFFF;
      font-size: 2rem;
      font-weight: 600;
    }
  }
  .link-content{
    padding: 40px 0;
    width: 100%;
    margin: 0 auto;
  }
}
</style>
