<template>
  <div>
<!--    顶部图片-->
    <div class="nav-detail-top">
      <img :src="topContent.bImage">
<!--      图片内容-->
     <div class="nav-detail-top-box">
       <h2>{{topContent.bName}}</h2>
       <p>{{topContent.bTitleOne}}</p>
       <p>{{topContent.bTitileTwo}}</p>
       <p>{{topContent.bTitileThree}}</p>
     </div>
    </div>

  <div class="nav-detail-second-box">
    <div class="nav-detail-title">
      {{secondType.tTitle}}
    </div>
    <div>{{secondType.tContext}}</div>
  </div>

<!--    我们的服务-->
    <div class="nav-detail-service-box">
      <div class="nav-detail-service-box-top">
       <div class="nav-detail-service-box-position">
         <div class="nav-detail-service-box-top-title">
           {{titleOne}}
         </div>
         <div class="nav-detail-tabs" v-if="screenWidth >980 ">
           <ul>
<!--             <li :class="navActiveValue  == 0? 'activeClass' :''" @click.stop="changeTapsFun(0)">助力增长</li>-->
             <li :class="navActiveValue  == index? 'activeClass' :''" @click.stop="changeTapsFun(item,index)" v-for="(item,index) in threeTypes" :key="index">{{item.tsTitle}}</li>
           </ul>
         </div>
       </div>
      </div>

      <!--         移动端-->
      <el-carousel style="height: 600px" v-if="screenWidth <980" >
        <el-carousel-item v-for="item in threeTypes" :key="item" style="height: 600px">
          <div style="background: #181c23;text-align: center; ">
            <span style="padding: 4rem 10rem;display: inline-block;border: 2px solid rgba(255,255,255,0.4);background: #FFFFFF">{{item.tsTitle}}</span>
          </div>
          <div class="nav-detail-content-l" style="min-height: 200px">
            <ul style="padding-left: 4rem">
              <li @click.stop="modelgoNavSonDetailFun(item.tFourLogo,itemSon)" v-for="(itemSon,index) in item.fourMTypeSons" :key="index">{{itemSon.tsTitle}}</li>

            </ul>
            <img :src="item.tsImage" style="width: 100%;">
          </div>

        </el-carousel-item>
      </el-carousel>
<!--      pc端-->
      <div class="nav-detail-content left-fade-in" :key="navActiveValue" >
        <div class="nav-detail-content-box" v-if="screenWidth >980 ">
          <div class="nav-detail-content-l">
            <ul>
              <li @click.stop="goNavSonDetailFun(item,navActiveValue)"  v-for="(item,index) in tsTitleUl.fourMTypeSons" :key="index">{{item.tsTitle}}</li>
            </ul>
          </div>
          <img :src="threeTypes[navActiveValue].tsImage"  />
        </div>
      </div>
    </div>

<!--   经典案例-->
    <div class="nav-detail-third">
      <div class="nav-detail-third-title">{{titleTwo}}</div>
<!--      <el-carousel indicator-position="outside">-->
<!--        <el-carousel-item v-for="item in typeImages" :key="item">-->
<!--          <img :src="item.mTypeViceImage">-->
<!--        </el-carousel-item>-->
<!--      </el-carousel>-->
      <div class="img-box" v-show="typeImagesActive == item.id"  v-for="(item,index) in typeImages" :key="index">
        <img class="img-box-bg" :src="item.mTypeImage">
        <img class="img-hover" :src="item.mTypeViceImage">
        <div class="box-text">{{item.mTypeText}}</div>
      </div>
<!--      底部按钮-->
      <div class="third-select-box">
        <div @click.stop="selectImgFun(item)" :class="typeImagesActive == item.id? 'activeValueClassImg':''" v-for="(item,index) in typeImages" :key="index"></div>
      </div>
    </div>
<!--近期新闻-->

    <div class="section__wrap related-wrap LatestNews " style="overflow-x:hidden;background: #FFFFFF ">
      <div class="xw-title" >近期新闻</div>
      <section class="related" style="background: #FFFFFF">
        <ul class="case-related" style="background: #FFFFFF">
          <li class="case-related__item" @click.stop="newsFun(item)" v-for="(item,index) in recentNew" :key="index">
            <a class="news__item"  target="_blank">
              <div class="news__item-img">
                <img :src="item.misImage" width="350" height="260">
              </div>
              <div class="news__item-cont">

                <span>{{item.misDate}}</span>
                <h3>{{item.misTitle}}</h3>
                <div class="news__item-cont__richtext">{{item.misIntro}}</div>
              </div>
            </a>
          </li>

        </ul>
      </section>
    </div>
<!--    荣誉与认证-->
    <div class="nav-detail-four">
      <H2>{{honorDetails.mtbTitle}}</H2>
      <div class="desc">{{honorDetails.mtbText}}</div>
      <div class="nav-detail-four-box">
        <div class="nav-detail-four-box-item" v-for="(item,index) in honorDetails.typeBottomValues" :key="index">
          <img :src="http + item.mtbImage" />
          <div class="logo-text">{{item.mtbTitle}}</div>
        </div>
      </div>
    </div>

<!--    底部-->
    <footerContent></footerContent>
  </div>
</template>

<script>
import footerContent from '@/components/footerContent'
import {getSecondClassApi} from '@/api/home'
import http from '@/configUrl'
export default {
  name: "navDetail",
  components:{footerContent},
  data(){
    return{
      http:http,
      navActiveValue:0,
      screenWidth: null, // 屏幕尺寸
      navItemValueFat:{},
    //  顶部内容
      topContent:{},
    //  二级内容标题文本
      secondType:{},
      //  新闻标题
      threeTypes:[],
      tsId:0,//二级Id
      tsTitleUl:[],
      recentNew:[],  //近期新闻
      honorDetails:{},  // 荣誉证书项目
      typeImages:[], // 经典案例

      typeImagesActive:0,
      titleOne:'',
      titleTwo:'',
    }
  },
  created() {
    this.screenWidth = document.body.clientWidth
    window.onresize = ()=>{
      return(()=>{
        this.screenWidth = document.body.clientWidth
      })()
    }
  },
  watch:{
    screenWidth: {
      handler: function (val,) {
        this.screenWidth=val

      },
      immediate: true
    }

  },
  mounted() {
    this.navItemValueFat  = JSON.parse(this.$route.query.itemValue)
    this.getSecondClassFun()
  },
  methods:{
    // 判断图片是相对路径爱是绝对路径
    isRelativePath(path) {
      // 匹配相对路径的正则表达式
      var regex = /^http?/ig;
      return regex.test(path);
    },
    //获取二级分类页面
    getSecondClassFun(){
      let data={
        Logo:this.navItemValueFat.tFourLogo
      }
      getSecondClassApi(data).then(response=>{
        this.topContent  = response.banner[0]
        this.titleOne = response.secondType.tFourTitle
        this.titleTwo = response.secondType.imageTitle
      //  顶部内容
        if(!this.isRelativePath(this.topContent.bImage)){
          this.topContent.bImage = http + this.topContent.bImage
        }
      //  二级内容标题文本
        this.secondType = response.secondType

        //  二级标题
        let threeTypesArr = response.threeTypes
        threeTypesArr.forEach(el=>{
          if(!this.isRelativePath(el.tsImage)){
            el.tsImage = http + el.tsImage
          }
          this.threeTypes.push(el)
        })
        this.tsId = this.threeTypes[0].tsId
        this.tsTitleUl = this.threeTypes[0]

      //  近期新闻
        let recentNewArr = response.recentNew.indexStore
        recentNewArr.forEach(el=>{
          if(!this.isRelativePath(el.misImage)){
            el.misImage = http +el.misImage
          }
          this.recentNew.push(el)
        })

        this.honorDetails  = response.honorDetails

      //  经典案例
        let typeImagesArr = response.typeImages
        this.typeImages = []
        typeImagesArr.forEach(el=>{
          if(!this.isRelativePath(el.mTypeViceImage)){
            el.mTypeViceImage = http + el.mTypeViceImage
          }
          if(!this.isRelativePath(el.mTypeImage)){
            el.mTypeImage = http + el.mTypeImage
          }
          this.typeImages.push(el)
        })
        this.typeImagesActive = this.typeImages[0].id
      })
    },
    changeTapsFun(item,value){
      if(value != this.navActiveValue){
        this.navActiveValue = value
        this.tsTitleUl = item
      }
    },
    goNavSonDetailFun(value,index){
    //  路由
      let obj = value
      obj.tFourLogo=this.threeTypes[index].tFourLogo
      let navValue = JSON.stringify(obj)
      this.$router.push({path:'/navSonDetail',query:{navValue:navValue}})

    },
    modelgoNavSonDetailFun(tFourLogo,value){
      let obj = value
      obj.tFourLogo=tFourLogo
      let navValue = JSON.stringify(obj)
      this.$router.push({path:'/navSonDetail',query:{navValue:navValue}})
    },
    newsFun(item){
      let value = JSON.stringify(item)
      this.$router.push({path:'/news',query:{value:value}})
    },
  //  经典案例 切换项目
    selectImgFun(value){
      this.typeImagesActive = value.id

    }
  }
}
</script>

<style scoped lang="scss">
.nav-detail-top{
  position: relative;
  box-sizing: border-box;
}

@media screen and (min-width: 1024px){

  .nav-detail-service-box-position{
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #181c23;
    text-align: center;
  }
  .nav-detail-tabs li{
    display: inline-block;
    width: 20%;
    height: 7rem;
    background: #ce1b2e;
    line-height: 7rem;
    text-align: center;
    margin: 0 4px;
    color: #FFFFFF;
    font-size: 1.4rem;
  }
  .nav-detail-tabs li:hover{
    color: rgba(255,255,255,0.4);
    cursor: pointer;
  }

  .nav-detail-content{
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 400px;
    .nav-detail-content-box{
      width: 80%;
      display: flex;
      justify-content: space-between;
      img{
        width: 40%;
        height: auto;
        object-fit: cover;

      }
    }
  }
  .nav-detail-content-l li{
    font-size: 1.4rem;
    padding: 2rem 0;
    color: #1c7bce;
    position: relative;
    padding-left: 4rem;
  }
  .nav-detail-content-l li:hover{
    color: #ce1b2e;
    cursor: pointer;
  }
  .nav-detail-content-l li::after{
    content: "";
    position: absolute;
    background:url("../assets/list-arr.png") no-repeat;
    width: 20px;
    height: 20px;
    top: 60%;
    left: 0;
    transform: translate(-50%,-50%);

  }
  .activeClass{
    background: #FFFFFF !important;
    color: #ce1b2e !important;
  }
  .xw-title{
    font-size: 2.4rem;
    text-align: center;
    padding: 4rem 0;
  }

  .nav-detail-four{
    padding-bottom: 20px;
    text-align: center;
    h2{
      font-size: 3rem;
      padding: 10rem 0  4rem 0;
      color: #1c7bce;
    }
    .nav-detail-four-box{
      display: flex;
      justify-content: center;
      padding: 20px 0 20px 0;
      .nav-detail-four-box-item{
        width: 20%;
        margin: 0 10px;
        box-sizing: border-box;
        border-right: 2px solid rgba(0,0,0,0.04);
      }
    }
  }
  .jqxw-box{
    width: 80%;
    margin: 0 auto;
    background: #FFFFFF;
  }
  .nav-detail-top img{
    object-fit: cover;
    width: 100%;
    /*height: 10em;*/
  }
  .nav-detail-top-box{
    position: absolute;
    width: 100%;
    bottom: 10%;
    color: #FFFFFF;
    box-sizing: border-box;
    padding: 0 20rem;
  }
  .nav-detail-second-box{
    width: 80%;
    margin: 0 auto;
    /*height: 200px;*/
    padding: 100px 0;

    text-align: center;
    .nav-detail-title{
      font-size:4rem;
      font-weight: bold;
      padding-bottom: 1.2rem;
    }
  }
  .nav-detail-second-box div:nth-child(2){
    font-size: 2rem;
    font-family: '微软雅黑';
  }
  .nav-detail-four-box-item{
    img{
      width: 200px;
      height: 200px;
      object-fit: contain;
    }
  }
  .nav-detail-third{
    background: #181c23;
    text-align: center;
    padding-bottom: 100px;
    .nav-detail-third-title{
      font-size: 2.4rem;
      color: #FFFFFF;
      text-align: center;
      padding: 4rem 0;
    }
    .img-box{
      width: 80%;
      margin: 0 auto;
      position: relative;
      .img-box-bg{
        width: 100%;
      }
      .img-hover{
        position: absolute;
        left: 4%;
        bottom: -4%;
        width: 30%;
        height: auto;
        //height: 10px;
      }
      .box-text{
        position: absolute;
        right: 10%;
        bottom: 20%;
        color: #FFFFFF;
        font-size: 2.4rem;
      }
    }
    .third-select-box{
      display: flex;
      justify-content: center;
      div{
        width: 14px;
        height: 14px;
        background: #FFFFFF;
        margin: 20px 4px 0 4px;
        cursor: pointer;
      }
    }
  }
  .img-hover{
    cursor: pointer;
    transition: all 0.6s;
  }
  .img-hover:hover{
    transform: scale(1.1);
  }

  .nav-detail-four{
    padding-bottom: 20px;
    text-align: center;
    h2{
      font-size: 3rem;
      padding: 10rem 0  4rem 0;
      color: #1c7bce;
    }
    .nav-detail-four-box{
      display: flex;
      justify-content: center;
      padding: 20px 0 20px 0;
      .nav-detail-four-box-item{
        width: 20%;
        margin: 0 10px;
        box-sizing: border-box;
        border-right: 2px solid rgba(0,0,0,0.04);
      }
    }
  }
  .nav-detail-four-box .nav-detail-four-box-item:last-child{
    border-right: none;
  }
}
@media screen and (max-width: 1024px) {
  .nav-detail-top img{
    object-fit: cover;
    width:100%;
    height: 300px;
  }
  .nav-detail-top-box{
    position: absolute;
    width: 100%;
    /*bottom: 0;*/
    text-align: center;
    bottom: 10%;
    color: #FFFFFF;
    box-sizing: border-box;
    padding: 0 10px;
  }

  .nav-detail-second-box{
    width: 80%;
    margin: 0 auto;
    /*height: 200px;*/
    padding: 100px 0;

    text-align: center;
    .nav-detail-title{
      font-size:2.8rem;
      font-weight: bold;
      padding-bottom: 1.2rem;
    }
  }
  .nav-detail-second-box div:nth-child(2){
    font-size: 1.6rem;
    font-family: '微软雅黑';
  }
  .nav-detail-four-box-item{
    img{
      width: 200px;
      height: 200px;
      object-fit: contain;
    }
  }

  .nav-detail-third{
    background: #181c23;
    text-align: center;
    padding-bottom: 100px;
    .nav-detail-third-title{
      font-size: 2.4rem;
      color: #FFFFFF;
      text-align: center;
      padding: 4rem 0;
    }
    .img-box{
      width: 80%;
      margin: 0 auto;
      position: relative;
      .img-box-bg{
        width: 100%;
      }
      .img-hover{
        position: absolute;
        left: 4%;
        bottom: -4%;
        width: 26%;
        //height: 10px;
        height: auto;
      }
      .box-text{
        position: absolute;
        right: 10%;
        bottom: 20%;
        color: #FFFFFF;
        font-size: 2.4rem;
      }
    }
    .third-select-box{
      display: flex;
      justify-content: center;
      div{
        width: 14px;
        height: 14px;
        background: #FFFFFF;
        margin: 20px 4px 0 4px;
        cursor: pointer;
      }
    }
  }
  .img-hover{
    cursor: pointer;
    transition: all 0.6s;
  }
  .img-hover:hover{
    transform: scale(1.1);
  }
}
@media screen and (max-width: 980px){
  .nav-detail-third{
    background: #181c23;
    text-align: center;
    .nav-detail-third-title{
      font-size: 2.4rem;
      color: #FFFFFF;
      text-align: center;
      padding: 4rem 0;
    }
    .xw-title{
      font-size: 2.4rem;
      color: #FFFFFF;
      text-align: center;
      padding: 4rem 0;
    }
    img{
      width: 80%;
      //height: auto;
      height: 200px;
      object-fit: fill;
    }
  }

  .nav-detail-four {
    padding-bottom: 20px;
    text-align: center;

    h2 {
      font-size: 3rem;
      padding: 10rem 0 4rem 0;
      color: #1c7bce;
    }
    .nav-detail-four-box-item{
      display: inline-block;
      padding: 20px;
      //width: 40%;
    }
  }
  .nav-detail-content-l li{
    font-size: 1.4rem;
    padding: 10px 0;
    color: #1c7bce;
    position: relative;
    padding-left: 4rem;
  }
}
@media screen and (min-width: 410px) and (max-width: 800px) {
  .nav-detail-third{
    background: #181c23;
    text-align: center;
    padding-bottom: 100px;
    .nav-detail-third-title{
      font-size: 2.4rem;
      color: #FFFFFF;
      text-align: center;
      padding: 4rem 0;
    }
    .img-box{
      width: 100%;
      margin: 0 auto;
      position: relative;
      .img-box-bg{
        width: 100%;
      }
      .img-hover{
        position: absolute;
        left: 4%;
        bottom: -4%;
        width: 140px;
        height: auto;
        //height: 10px;
      }
      .box-text{
        position: absolute;
        right: 10%;
        bottom: 20%;
        color: #FFFFFF;
        font-size: 1.2rem;
      }
    }
    .third-select-box{
      display: flex;
      justify-content: center;
      div{
        width: 14px;
        height: 14px;
        background: #FFFFFF;
        margin: 20px 4px 0 4px;
        cursor: pointer;
      }
    }
  }
  .img-hover{
    cursor: pointer;
    transition: all 0.6s;
  }
  .img-hover:hover{
    transform: scale(1.1);
  }
  .nav-detail-service-box-position{
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #181c23;
    text-align: center;
  }
  .nav-detail-tabs li{
    display: inline-block;
    width: 20%;
    height: 7rem;
    background: #ce1b2e;
    line-height: 7rem;
    text-align: center;
    margin: 0 4px;
    color: #FFFFFF;
    font-size: 1.4rem;
  }
  .nav-detail-tabs li:hover{
    color: rgba(255,255,255,0.4);
    cursor: pointer;
  }

  .nav-detail-content{
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 400px;
    .nav-detail-content-box{
      width: 80%;
      display: flex;
      justify-content: space-between;
      img{
        width: 40%;
        height: auto;
        object-fit: cover;

      }
    }
  }
  .nav-detail-content-l li{
    font-size: 1.4rem;
    padding: 2rem 0;
    color: #1c7bce;
    position: relative;
    padding-left: 4rem;
  }
  .nav-detail-content-l li:hover{
    color: #ce1b2e;
    cursor: pointer;
  }
  .nav-detail-content-l li::after{
    content: "";
    position: absolute;
    background:url("../assets/list-arr.png") no-repeat;
    width: 20px;
    height: 20px;
    top: 60%;
    left: 0;
    transform: translate(-50%,-50%);

  }
  .activeClass{
    background: #FFFFFF !important;
    color: #ce1b2e !important;
  }
  .xw-title{
    font-size: 2.4rem;
    text-align: center;
    padding: 4rem 0;
  }

  .nav-detail-four{
    padding-bottom: 20px;
    text-align: center;
    h2{
      font-size: 3rem;
      padding: 10rem 0  4rem 0;
      color: #1c7bce;
    }
    .nav-detail-four-box{
      display: flex;
      justify-content: center;
      padding: 20px 0 20px 0;
      .nav-detail-four-box-item{
        width: 20%;
        margin: 0 10px;
        box-sizing: border-box;
        border-right: 2px solid rgba(0,0,0,0.04);
      }
    }
  }
  .nav-detail-four-box .nav-detail-four-box-item:last-child{
    border-right: none;
  }
}
ul li{
  list-style: none;

}
ul{
  padding: 0;
  margin: 0;

}
//  我的服务
.nav-detail-service-box-top{
  background: #181c23;
  position: relative;
  height: 200px;
  border-bottom: 6px solid rgba(255,255,255,0.6)
}
.nav-detail-service-box-top-title{
  color: #FFFFFF;
  text-align: center;
  font-size: 2.7rem;
  padding: 2rem 0;
}

.left-fade-in{
  animation-duration: 1s; /* 动画时间 */
  animation-name: leftFadein; /* 关键帧名字 */
}
@keyframes leftFadein {
  0% {
    opacity: 0;
    transform: translateX(-600px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.activeValueClassImg{
  background: #ce1b2e !important;
}

</style>
