<template>
  <div>
    <div class="navSonDetail-top">
      <img :src="banner[0].bImage">
      <div class="top-text">
        <h2>{{banner[0].bTitleOne}}</h2>
        <h2>{{banner[0].bTitileTwo}}</h2>
        <h2>{{banner[0].bTitileThree}}</h2>
      </div>
    </div>
    <div class="navSonDetail-title">{{navItemValueFat.tsTitle}}</div>
    <div class="navSonDetail-text">
      <div v-html="navItemValueFat.tsText"></div>
    </div>

    <div class="navSonDetail-title">我们的服务</div>
    <div class="navSondrtail-bottom-text" style="text-align: center;padding: 10px 0 ">中电文思海辉拥有超强的数字化咨询服务与行业解决方案技术实施能力，致力于成为全球企业最具价值的业务合作伙伴。</div>

<!--    我的服务-->

<!--    <div class="nav-service-box">-->
<!--     <div>-->
<!--       <div class="nav-service-item">-->
<!--         <img src="../assets/jsss.jpg"/>-->
<!--       </div>-->
<!--       <div class="nav-service-item">-->
<!--         <img src="../assets/tyjh.jpg"/>-->
<!--       </div>-->
<!--       <div class="nav-service-item">-->
<!--         <img src="../assets/zxfw.jpg"/>-->
<!--       </div>-->
<!--       <div class="nav-service-item">-->
<!--         <img src="../assets/yyfw.jpg"/>-->
<!--       </div>-->
<!--     </div>-->
<!--    </div>-->
    <div style="position: relative;">
      <div class="cards" style="display: flex;justify-content: center">
        <div class="cards__item" v-for="(item,index) in typesNav" :key="index">
          <img :src="item.tImage"  width="350" height="400">
          <div class="cards__item-content">
            <h3>{{item.tName}}</h3>
            <ul>
              <li class="itemSourceChild" v-for="(itemSon,index) in item.secondTypes " :key="index">
                <svg id="icon-ico-arrow" class="icon icon-ico-arrow" aria-hidden="true" role="img" viewBox="0 0 102.84 32.52" width="100%" height="100%"><path d="M102.84 14.51L0 0v14.51h102.84zM0 18.01v14.51l102.84-14.51H0z"></path></svg>
                <a @click.stop="navFun(itemSon)">{{itemSon.tName}}</a>
              </li>

            </ul>
            <!--          <a class="link-arrow no-border" href="/technology">了解更多</a>-->
          </div>
          <div class="animated-strip-top"></div>
          <div class="animated-strip-bot"></div>
        </div>


      </div>
    </div>
    <footerContent></footerContent>
  </div>
</template>

<script>
import http from '@/configUrl'
import footerContent from "@/components/footerContent";
import {getThreeApi} from "@/api/home"
export default {
  name: "navSonDetail",
  components:{
    footerContent
  },
  data(){
    return{
      navItemValueFat:{},
      typesNav:[],
      banner:[],
    }
  },
  created() {
    this.navItemValueFat  = JSON.parse(this.$route.query.navValue)
    this.getThreeFun()
  },
  methods:{
    navFun(item){
      // this.headerActiveHome = item.value
      let navValue = JSON.stringify(item)
      this.$router.replace({path:'/navDetail',query:{itemValue:navValue}})
    },
    // 判断图片是相对路径爱是绝对路径
    isRelativePath(path) {
      // 匹配相对路径的正则表达式
      var regex = /^http?/ig;
      return regex.test(path);
    },
    getThreeFun(){
      let data = {Logo:this.navItemValueFat.tFourLogo}
      getThreeApi(data).then((res)=>{
        // console.log(res)
        let typesNavArr = res.types
        typesNavArr.forEach(el=>{
          if(!this.isRelativePath(el.tImage)){
            el.tImage = http + el.tImage
          }
          this.typesNav.push(el)
        })

        let bannerArr = res.banner
        bannerArr.forEach(el=>{
          if(!this.isRelativePath(el.bImage)){
            el.bImage = http +el.bImage
          }

          this.banner.push(el)

        })

      })
    }
  },
}
</script>

<style scoped lang="scss">

li{
  list-style: none;
}
ul{
  padding: 0;
}
@media screen and (min-width: 1024px){
  .nav-service-item::after{
    content: "";
    position: absolute;
    width: 6px;
    height:6px;
    top: 40%;
    left:9.6%;
    background: #ce1b2e;
  }
  .navSonDetail-top{
    position: relative;
    img{
      width: 100%;
      height: auto;
    }
    .top-text{
      position: absolute;
      //width: 100%;
      top: 50%;
      left: 20%;
      transform: translate(-50%,-50%);
      color: #FFFFFF;
      font-size: 4rem;
      h2{
        font-size: 2rem;
      }
    }
  }
  .navSonDetail-text,.navSondrtail-bottom-text{
    width: 60%;
    margin: 0 auto;
  }

}
@media screen and (max-width: 1024px) and (min-width: 300px){
  .nav-service-item::after{
    content: "";
    position: absolute;
    width: 6px;
    height:6px;
    top: 40%;
    left:8%;
    background: #ce1b2e;
  }
  .navSonDetail-text,.navSondrtail-bottom-text{
    width: 80%;
    margin: 0 auto;
  }
  .navSonDetail-top{
    position: relative;
    img{
      width: 100%;
      height: 300px;
      object-fit: fill;
    }
    .top-text{
      position: absolute;
      //width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      color: #FFFFFF;
      font-size: 2rem;
      h2{
        font-size: 1.4rem;
      }
    }
  }

}
.navSonDetail-title{
  padding: 10rem 0;
  text-align: center;
  color: #01a2ff;
  font-size: 3rem;
}
.navSonDetail-text-item{
  h3{
    font-weight: bold;
    font-size: 2.4rem;
  }
  p{
    font-size: 1.5rem;
  }
  li{
    position: relative;
    padding-left: 20px;
    font-size: 1.4rem;
    img{
      width: 20px;
      height: 10px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0%,-50%);
    }
  }
}
.nav-service-box >div{
  display: flex;
  justify-content: center;
}
.nav-service-box .nav-service-item{
  box-sizing: border-box;
  width: 25%;
  margin: 0 0.1rem;
  height: auto;
  position: relative;
  img{
    width: 100%;
    filter:blur(1px) ;
  }
}
.nav-service-item::before{
  z-index: 1;
  content: "";
  position: absolute;
  left: 10%;
  width: 1px;
  height: 40%;
  background: rgba(255,255,255,0.4);
  top: 0;
}

</style>
