import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from "vue-router";
import router from './router'
Vue.use(ElementUI);
Vue.use(VueRouter)
Vue.config.productionTip = false


router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
