import Vue from "vue"
import VueRouter from "vue-router";
import navMenu from "@/pages/navMenu";
import home from '@/pages/home'
import navDetail from "@/pages/navDetail";
import navSonDetail from "@/pages/navSonDetail";
import news from "@/pages/news";
import contactUs from "@/pages/contactUs";
import friendlyLinks from "@/pages/friendlyLinks";
import aggremment from "@/pages/aggremment";
Vue.use(VueRouter)


const routes=[
    {
        path:'/',// 程序默认启动路由
        component:navMenu,
        redirect:'/home',
        children:[
            {
                path:'/home',
                meta:{title:'首页'},
                component:home,
            },
            {
                path:'/navDetail',
                component:navDetail,
            },
            {
                path:'/navSonDetail',
                component:navSonDetail,
            },
            {
                path:'/contactUs',
                component:contactUs,
            },
            {
                path:'/news',
                component:news,
            },
            {
                path:'/friendlylinks',
                component: friendlyLinks
            },
            {
                path:'/aggremment',
                component: aggremment
            }
        ]
    }
]


const router = new VueRouter({
    mode:'history',
    routes
})



export default  router
