<template>
  <div class="footer-detail">
    <div class="footer-detail-top">
      <h3>关注我们</h3>
      <img v-for="(item,index) in contactUsContent" :key="index" :src="item.logo" @click.stop="openDiaFun(item)" />
<!--      <img src="../assets/weibo.png" />-->
    </div>

    <div class="footer-center" v-if="isMobileValue">
      <div class="footer-center-box">
        <div class="footer-center-box-item" v-for="(item,index) in footerIndices" :key="index">
        <div class="footer-center-box-item-top" @click.stop="openFun(index)">
          <h3>{{item.typeName}}</h3>
          <div class="footer-center-box-item-fh-box">
            <img v-if="isMobileValue && activeFooterShow != index" src="../assets/jia.png">
            <img v-else src="../assets/jian.png">
          </div>
        </div>
         <div class="footer-center-box-item-group" v-if="isMobileValue && activeFooterShow == index">
           <ul>
             <li @click.stop="goFrindlyLinkFun(itemSon)" v-for="(itemSon,index) in item.footerIndexValues" :key="index">{{itemSon.name}}</li>
           </ul>

         </div>
        </div>
      </div>
    </div>

    <div class="footer-center" v-else>
      <div class="footer-center-box">
        <div class="footer-center-box-item" v-for="(item,index) in footerIndices" :key="index">
          <div class="footer-center-box-item-top">
            <h3>{{item.typeName}}</h3>

          </div>
          <div class="footer-center-box-item-group">
            <ul>
              <li @click.stop="goFrindlyLinkFun(itemSom)" v-for="(itemSom,index) in item.footerIndexValues" :key="index">{{itemSom.name}}</li>
            </ul>

          </div>
        </div>

      </div>
    </div>

    <div class="footer-botoom">
      <div class="footer-botoom-title">{{corporation.cGongAn}}</div>
      <div class="footer-botoom-desc" @click.stop="openOtherUrlFun()" style="cursor: pointer">{{corporation.cGongXin}}</div>
      <div class="footer-botoom-item">
        <span @click.stop="aggremmentFun(item)" v-for="(item,index) in aggremmentObj"  :key=index>{{item.maTitle}}    </span>

      </div>
    </div>


    <el-dialog

        :visible.sync="dialogVisible"
        width="30%"
        >
      <img :src="nowSrc" class="codeSize"/>
      <span slot="footer" class="dialog-footer">
  </span>
    </el-dialog>
  </div>
</template>

<script>
import http from '@/configUrl'
import {getFooterInfoApi,home} from '@/api/home'
export default {
  name: "footer",
  data(){
    return{
      dialogVisible:false,
      activeFooterShow:999,
      isMobileValue:false,
      contactUsContent:[],
      nowSrc:'',
      footerIndices:[], //友情连接
      corporation:{},
      aggremmentObj:{},
    }
  },
  created() {
    this.isMobile()
    this.getFooterInfoFun()
  },
  methods:{
    openOtherUrlFun(){
      window.open('https://beian.miit.gov.cn/')
    },
    //弹出层
    openDiaFun(value){
      this.nowSrc = value.erCode
      this.dialogVisible=true
    },
    //获取底部内容
    getFooterInfoFun(){
      getFooterInfoApi().then(res=>{
        if(res.code == 200){
          // this.contactUsContent
          this.contactUsContent=[]
          let contactUsContentArr = res.contactInformation
          contactUsContentArr.forEach(el=>{
            if(!this.isRelativePath(el.erCode)){
              el.erCode = http +el.erCode
            }
            if(!this.isRelativePath(el.logo)){
              el.logo = http +el.logo
            }
            this.contactUsContent.push(el)
          })
          this.footerIndices = res.footerIndices
        }

        this.aggremmentObj = res.agreements
        home().then(response=>{
          this.corporation = response.corporation
        })
      })
    },
    // 判断图片是相对路径爱是绝对路径
    isRelativePath(path) {
      // 匹配相对路径的正则表达式
      var regex = /^http?/ig;
      return regex.test(path);
    },
    isMobile(){
      if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        this.isMobileValue =  true; // 移动端
      }else{
        this.isMobileValue =  false; // 移动端
      }
    },
    openFun(index){
      this.activeFooterShow = index
    },
  //  跳转友情链接
    goFrindlyLinkFun(value){
      let linkValue = JSON.stringify(value)
      this.$router.push({path:'/friendlylinks',query:{linkValue:linkValue}})
    },
  //  跳转协议
    aggremmentFun(value){
      let linkValue = JSON.stringify(value)
      this.$router.push({path:'/aggremment',query:{linkValue:linkValue}})
    }
  }
}
</script>

<style scoped lang="scss">
ul{
  padding: 0;
}
.footer-detail{
  background: #181c23;
}
.footer-detail-top{
  display: flex;
  justify-content: center;
  //align-items: center;
  padding: 4rem 0;
  h3{
    //color: #FFFFFF;
    font-weight: 600;
    color: rgba(255,255,255,0.7);
  }
  img{
    width: 6rem;
    height: 6rem;
  }
}
ul li{
  list-style: none;
}
.footer-center{
  border-top: 1px solid rgba(255,255,255,0.6);
  border-bottom: 1px solid rgba(255,255,255,0.6);

}
//媒体查询
@media screen and (max-width: 750px) {
  .codeSize{
    width: 100%;
    height: 50%;
  }
  .footer-center-box-item{
    border-bottom: 1px solid rgba(255,255,255,0.6);
    h3{
      text-align: center;
      color: #FFFFFF;
    }
    li{
      color: rgba(255,255,255,0.7);
      font-size: 1.4rem;
      padding: 4px 10px;
    }
  }
  .footer-center-box-item-top{
    position: relative;
    img{
      width: 20px;
      height: 20px;
    }
    .footer-center-box-item-fh-box{
      position: absolute;
      right:20px;
      top: 0;
    }
  }
}
@media screen and (min-width: 750px){
  .codeSize{
    width: 100%;
    height: 50%;
  }
 .footer-center-box{
   .footer-center-box-item-fh-box{
     display: none;
   }
   h3{
     padding-left: 20px;
     color: #FFFFFF;
   }
   box-sizing: border-box;
   display: flex;
   justify-content: center;
   width: 80%;
   margin: 0 auto;
   ul{
     display: inline-block;
     margin: 0 20px;
   }
   .footer-center-box-item{
     width: 30%;
     padding: 20px 0;
   }

 }
  .footer-center-box  .footer-center-box-item:nth-child(2){
    border-left: 1px solid rgba(255,255,255,0.6);
    border-right: 1px solid rgba(255,255,255,0.6);
  }
  .footer-center-box li{
    color: rgba(255,255,255,0.6);
    font-size: 1.4rem;
    padding: 4px 0;
  }
  .footer-center-box li:hover{
    color: #ce1b2e;
    cursor: pointer;
  }
}
.footer-botoom{
  padding: 20px 0;
  text-align: center;
  .footer-botoom-title{
    font-size: 1.4rem;
    color: rgba(255,255,255,0.6);
  }
  .footer-botoom-desc{
    color: rgba(255,255,255,0.8);
    font-size: 1.6rem;
    padding: 10px 0;
  }
.footer-botoom-item{
  color: rgba(255,255,255,0.6);
  font-size: 1rem;
}
}
.footer-botoom-item span:hover{
  color: #ce1b2e;
  cursor: pointer;
}
.footer-botoom-item{
  span{
    display: inline-block;
    padding: 0 6px;
    border-right: 1px solid #FFFFFF;
  }
}
.footer-botoom-item span:last-child{
  border: none;
}
</style>
