import request from "@/request";
export function home(){
    return request({
        method:'get',
        url:'/api/indexControls/getIndexPanel'
    })
}
export function getSecondClassApi(data){
    return request({
        method:'get',
        url:'/api/indexControls/getSonIndex',
        params:data
    })
}
//获取底部内容
export function getFooterInfoApi(){
    return request({
        method:'get',
        url:'/api/indexControls/getFooter'
    })
}
//获取三级页面
export function getThreeApi(data){
    return request({
        method:'get',
        url:'/api/indexControls/getThreeIndex',
        params:data
    })
}

//联系我们
export function  contactUsApi(data){
    return request({
        method:'POST',
        url:'/api/indexControls/insertConnection',
        data:data
    })
}
