<template>
  <div class="news-box">
    <div class="news-top">
      <img :src="newsContent.misImage" />
      <h2>{{newsContent.misTitle}}</h2>
    </div>
    <div class="news-content">
     <p>{{newsContent.misRichText}}</p>
    </div>
    <footerContent></footerContent>
  </div>
</template>

<script>
import footerContent from "@/components/footerContent";
export default {
  name: "news",
  components:{
    footerContent
  },
  data(){
    return{
      newsContent:{}
    }
  },
  mounted() {
    this.newsContent=JSON.parse(this.$route.query.value)
  },
  methods:{

  }
}
</script>

<style scoped lang="scss">
@media screen and (min-width: 1024px){
  .news-top{
    width: 100%;
    img{
      width: 100%;
    }
    h2{
      position: absolute;
      top: 20%;
      left: 10%;
      width: 60%;
      color: #FFFFFF;
      font-size: 4rem;
    }
  }
  .news-content{
    padding: 20px 0;
    width: 60%;
    margin: 0 auto;
    p{
      font-size: 1.8rem;
      line-height: 3rem;
      padding: 10px 0;
    }
  }
}
@media screen and (min-width: 765px) and (max-width: 1024px) {
  .news-top{
    width: 100%;
    img{
      width: 100%;
      height: 300px;
      object-fit: fill;
    }
    h2{
      position: absolute;
      top: 20%;
      left: 10%;
      width: 60%;
      color: #FFFFFF;
      font-size: 3rem;
    }
  }
  .news-content{
    padding: 20px 0;
    width: 80%;
    margin: 0 auto;
    p{
      font-size: 1.8rem;
      line-height: 3rem;
      padding: 10px 0;
    }
  }
}
@media screen and (max-width: 765px){
  .news-top{
    width: 100%;
    img{
      width: 100%;
      height: 300px;
      object-fit: fill;
    }
      h2{
        position: absolute;
        top: 20%;
        left: 0%;
        //width: 100%;
        color: #FFFFFF;
        font-size: 3rem;
        text-align: center;
      }
  }
  .news-content{
    padding: 20px 0;
    width: 90%;
    margin: 0 auto;
    p{
      font-size: 1.8rem;
      line-height: 3rem;
      padding: 10px 0;
    }
  }

}
.news-top{
  position: relative;
}
</style>
